export var SearchList = [
  { labe: '考评指标代码', code: 'evaluationCode', type: 'input' },
  { labe: '考评指标描述', code: 'evaluationName', type: 'input' }

]
export var SearchData = {
  evaluationCode: '',
  evaluationName: ''
}
export var menudata = [{
  label: '+新增',
  action: 'CreateaStatement',
  id: 1
}]
