<template>
<!-- 考评指标定义 -->
  <div>
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData" ></list-search>
    <div class="exportData">
      <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
    </div>
    <div class="tableList">
      <div class="table">
        <el-table :data="form.addList" stripe style="width: 100%">
          <!-- <el-table-column type="selection" ></el-table-column> -->
          <el-table-column prop="evaluationCode" label="考评指标代码" width="" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="evaluationName" label="考评指标描述" width=""  :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.evaluationName" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="status" width="80px"  label="启用" >
            <template #default="scope">
              <el-checkbox :disabled=scope.row.showDisabled v-model="scope.row.status"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column width="100px" label="次级指标">
            <template #default="scope">
              <el-button type="text" size="mini" @click="SecondaryIndicators(scope.row)">次级指标</el-button>
            </template>
          </el-table-column>
          <el-table-column width="150px" label="操作">
            <template #default="scope">
              <el-button v-if="scope.row.edStart === 1" type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-button v-else type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
              <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size = pageSize
          layout="total,  prev, pager, next, jumper"
          :total = total>
          </el-pagination>
        </div>
      </div>
    </div>
    <dialog-box ref="AddNewData" componentName="EvaluationAddDialog" dialogWidth="700px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { SearchList, SearchData, menudata } from './js/evaluationIndicators'
export default {
  name: 'EvaluationIndicators',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      childData: { searchList: SearchList, searchData: SearchData },
      menudata: menudata,
      AddcheckDialogShow: false,
      tableData: [{
        code: 'QUALITY_CRITERIA'
      }],
      form: {
        addList: [
          {
            cpZb: 'BUYER_CRITERIA',
            cpMs: '采购部指标',
            enable: true
          },
          {
            cpZb: 'PRODUCT_CRITERIA',
            cpMs: '产品部指标',
            enable: true
          },
          {
            cpZb: 'QUALITY_CRITERIA',
            cpMs: '品管部指标',
            enable: true
          }
        ]
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取考评指标定义列表
    getDataList (data) {
      const obj = {
        ...data
      }
      request('/api/evaluation/index/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.form.addList = res.data.records
          this.total = res.data.total
          this.form.addList.forEach(item => {
            item.edStart = 1
            item.showDisabled = true
          })
        }
      })
    },

    // 跳转次级指标
    SecondaryIndicators (data) {
      this.$router.push({
        params: { viewName: 'DefinitionTable' },
        query: {
          id: data.id,
          code: data.evaluationCode,
          name: data.evaluationName
        }
      })
    },

    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.evaluationCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标代码'
        })
        return false
      }
      if (!newsArr.evaluationName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标描述'
        })
        return false
      }

      const obj = {
        evaluationCode: newsArr.evaluationCode,
        evaluationName: newsArr.evaluationName,
        status: newsArr.status
      }
      request('/api/evaluation/index/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
      })
      this.AddcheckDialogShow = false
    },

    // 保存
    preservation (row) {
      if (!row.evaluationCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标代码'
        })
        return false
      }
      if (!row.evaluationName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标描述'
        })
        return false
      }
      const obj = {
        ...row
      }
      request('/api/evaluation/index/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          this.getDataList()
        }
      })
    },
    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/index/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },

    // 查询
    onSearch (data) {
      this.getDataList(data)
    },
    // 清空
    clearSearch (data) {
      this.getDataList(data)
    },

    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },

    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/tablePadding.scss';
.exportData{
  padding: 0 30px;
  .el-button{
    font-size: 16px;
  }
}
.tableList{
  margin-top: 0;
  .table{
    .el-table td{
      padding: 4px 0;
    }
  }
  :deep(.el-input__inner){
    height: 34px;
    line-height: 34px;
  }
  .is-disabled{
    :deep(.el-input__inner){
      background: none;
      border: none;
      color: #606266;
    }
  }
}
</style>
